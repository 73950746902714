import React from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby"

function HoverButton({ title, link, hover, dark, ...props }) {
    const className = dark ? `button outline ${hover ? "darkon" : "darkoff"}` : `button outline ${hover ? "on" : "off"}`;
    if (validURL(link) || String(link).includes("mailto:")) {
        return (
            <a href={link}>
                <motion.div animate transition={{ duration: 0.2 }} className={className} {...props} >
                    {title}
                </motion.div>
            </a>

        );
    }
    else {
        return (
            <Link to={link}>
                <motion.div animate transition={{ duration: 0.2 }} className={className} {...props} >
                    {title}
                </motion.div>
            </Link>
        );
    }
}

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export default function Button({ name, to, dark }) {
    const [hover, setHover] = useState(false);
    return <HoverButton title={name} link={to} hover={hover} dark={dark} onHoverStart={() => setHover(!hover)} onHoverEnd={() => setHover(!hover)} />;
}