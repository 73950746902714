import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "gatsby"
import Button from "./button"

const isBrowser = typeof window !== `undefined`


const Path = props => (
    <motion.path
        fill="transparent"
        strokeWidth="2"
        stroke="#e5e5e5"
        strokeLinecap="round"
        {...props}
    />
);

const desktopBg = {
    open: {
        x: 0, transition: {
            type: "spring",
            stiffness: 250,
            damping: 40,
            mass: 0.4
        }
    },
    closed: {
        x: -450, transition: {
            type: "spring",
            stiffness: 400,
            damping: 40,
            mass: 0.4
        }
    }
};

const desktopNav = {
    open: {
        x: 335, transition: {
            type: "spring",
            stiffness: 250,
            damping: 40,
            mass: 0.4

        }
    },
    closed: {
        x: 0, transition: {
            type: "spring",
            stiffness: 400,
            damping: 40,
            mass: 0.4
        }
    }
};

const mobileBg = {
    open: {
        x: 0, transition: {
            type: "spring",
            stiffness: 250,
            damping: 40,
            mass: 0.4
        }
    },
    closed: {
        x: isBrowser ? (-window.innerWidth * 1) - 20 : 800, transition: {
            type: "spring",
            stiffness: 400,
            damping: 40,
            mass: 0.4
        }
    }
};

const mobileNav = {
    open: {
        x: isBrowser ? window.innerWidth : 500, transition: {
            type: "spring",
            stiffness: 250,
            damping: 40,
            mass: 0.4
        }
    },
    closed: {
        x: 0, transition: {
            type: "spring",
            stiffness: 400,
            damping: 40,
            mass: 0.4
        }
    }
};

export const Navigation = ({ toggle }) => (
    <motion.div onClick={toggle} className="navigation-parent">
        <motion.div variants={desktopNav} data-w-id="9c7ac22b-8cb6-e716-61c1-d46a60e2e172" className="navigation">
            <div className="navigation-toggle">
                <div id="w-node-d46a60e2e174-60e2e172" className="navigation-toggle-icon desktop">
                    <motion.svg whileHover={{ scale: 1.2 }} transition={0.03} whileTap={{ scale: 0.8 }} width="30" height="35"
                        viewBox="0 0 35 35">
                        <Path variants={{
                            closed: { d: "M 0 6 L 35 6" },
                            open: { d: "M 5.1 29.9 L 29.9 5" }
                        }} />
                        <Path d="M 0 17.5 L 35 17.5" variants={{
                            closed: { opacity: 1 },
                            open: { opacity: 0 }
                        }} transition={{ duration: 0.03 }} />
                        <Path variants={{
                            closed: { d: "M 0 28 L 35 28" },
                            open: { d: "M 5.1 5.1 L 29.9 29.9" }
                        }} />
                    </motion.svg>
                </div>
                <div id="w-node-d46a60e2e178-60e2e172" className="navigation-toggle-text">
                    <div>Table of Contents</div>
                </div>
            </div>
            <div className="navigation-link-parent">
                <Link to="/">
                    <div className="big-button w-inline-block">Home</div>
                </Link>
                <Link to="/about/">
                    <div className="big-button w-inline-block">About</div>
                </Link>
                <Link to="/issues/">
                    <div className="big-button w-inline-block">Issues</div>
                </Link>
                <Link to="/blog/">
                    <div className="big-button w-inline-block">Blog</div>
                </Link>
                <Link to="/staff/">
                    <div className="big-button w-inline-block">Staff</div>
                </Link>
                <Link to="/help-out/">
                    <div className="big-button w-inline-block">Help Out</div>
                </Link>
            </div>
            <div className="navigation-misc-links"><a href="https://www.instagram.com/wheatonpub/" target="_blank" rel="noopener noreferrer" className="text-link">Instagram</a><a href="mailto:wheatonpub@my.wheaton.edu"
                className="text-link trailing">Contact</a></div>
        </motion.div>
        <motion.div variants={desktopBg} className="navigation-background" />
    </motion.div>
);
export const MobileNav = ({ toggle }) => (
    <div>
        <motion.div variants={mobileNav} data-w-id="9c7ac22b-8cb6-e716-61c1-d46a60e2e172" className="navigation">
            <div className="navigation-link-parent">
                <Link to="/">
                    <div className="big-button w-inline-block">Home</div>
                </Link>
                <Link to="/about/">
                    <div className="big-button w-inline-block">About</div>
                </Link>
                <Link to="/issues/">
                    <div className="big-button w-inline-block">Issues</div>
                </Link>
                <Link to="/blog/">
                    <div className="big-button w-inline-block">Blog</div>
                </Link>
                <Link to="/staff/">
                    <div className="big-button w-inline-block">Staff</div>
                </Link>
                <Link to="/help-out/">
                    <div className="big-button w-inline-block">Help Out</div>
                </Link>
            </div>
            <div className="navigation-misc-links"><a href="https://www.instagram.com/wheatonpub/" className="text-link">Instagram</a><a href="mailto:wheatonpub@my.wheaton.edu"
                className="text-link trailing">Contact</a></div>
            <div className="navigation-mobile-links">
                <Link to="/submissions">
                    <div className="button w-inline-block">Submit Work</div>
                </Link>
                <Button name="Latest Issue" to="/issues/fall-2024/" />
            </div>
        </motion.div>
        <motion.div variants={mobileBg} className="navigation-background" />
    </div>
);
