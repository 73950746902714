import React from "react"
import Helmet from "react-helmet"
import seoImage from "../images/seoImage.png"

function SEO({ title }) {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            {/* <!-- Primary Meta Tags --> */}
            <title>{title} | The Pub</title>
            <meta name="description" content="The Pub is an independent journal, unhindered by institutional expectations, and designed to fill the spaces that other campus publications leave empty. Put simply, The Pub is by the students, for the students." />

            {/* <!-- Open Graph / Facebook --> */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://wheatonpub.com/" />
            <meta property="og:title" content="The Pub, Independent Journal" />
            <meta property="og:description" content="The Pub is an independent journal, unhindered by institutional expectations, and designed to fill the spaces that other campus publications leave empty. Put simply, The Pub is by the students, for the students." />
            <meta property="og:image" content={seoImage} />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content={seoImage} />
            <meta property="twitter:url" content="https://wheatonpub.com/" />
            <meta property="twitter:title" content="The Pub, Independent Journal" />
            <meta property="twitter:description" content="The Pub is an independent journal, unhindered by institutional expectations, and designed to fill the spaces that other campus publications leave empty. Put simply, The Pub is by the students, for the students." />
            <meta property="twitter:image" content=""></meta>b

        </Helmet>
    )
}

export default SEO