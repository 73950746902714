import * as React from "react";
import { motion, useViewportScroll, useTransform, useCycle, useSpring } from "framer-motion"
import { useRef } from "react";
import { Navigation, MobileNav } from "./navigation";
import logo from "../images/light.svg"
import footerLogo from "../images/footerLogo.svg"
import { Link } from "gatsby"
import Button from "./button"

const Path = props => (
    <motion.path
        fill="transparent"
        strokeWidth="2"
        stroke="#e5e5e5"
        strokeLinecap="round"
        {...props}
    />
);
const damping = 200;
const stiffness = 0;

export const Layout = ({ children, data }, props) => {

    const { scrollYProgress } = useViewportScroll();

    const containerRef = useRef(null);
    const [isOpen, toggleOpen] = useCycle(false, true);

    const isBrowser = typeof window !== `undefined`
    const rawHTML =
        (
            <motion.div ref={containerRef} initial={false} animate={isOpen ? "open" : "closed"} className="layout">
                <div className="header"><Link to="/"><img src={logo} alt="The Pub Logo" className="header-logo w-inline-block" /></Link>
                    <div className="button-parent">
                        <Link to="/submissions">
                            <div className="button w-inline-block">Submit Work</div>
                        </Link>
                        <Button name="Latest Issue" to="/issues/fall-2024" />
                    </div>
                    <motion.div className="navigation-toggle-icon" onClick={() => toggleOpen()} >
                        <motion.svg whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.8 }} width="30" height="35"
                            viewBox="0 0 35 35">
                            <Path variants={{
                                closed: { d: "M 0 6 L 35 6" },
                                open: { d: "M 5.1 29.9 L 29.9 5" }
                            }} />
                            <Path d="M 0 17.5 L 35 17.5" variants={{
                                closed: { opacity: 1 },
                                open: { opacity: 0 }
                            }} transition={{ duration: 0.03 }} />
                            <Path variants={{
                                closed: { d: "M 0 28 L 35 28" },
                                open: { d: "M 5.1 5.1 L 29.9 29.9" }
                            }} />
                        </motion.svg>
                    </motion.div>
                </div>
                {children}
                <div className="desktop-nav"><Navigation toggle={() => toggleOpen()} /></div>
                <div className="mobile-nav"><MobileNav toggle={() => toggleOpen()} /></div>
                <div className="footer">
                    <div className="footer-grid">
                        <div id="w-node-5bb374513154-74513152" className="footer-container">
                            <h1 className="footer-h1">Wheaton&#x27;s Independent<br />Undergraduate<br />Academic Journal</h1>
                        </div>
                        <div id="w-node-5bb374513169-74513152" className="footer-column">
                            <h4 className="footer-h4">Issues</h4>
                            <Link to="/issues/fall-2024/" className="text-link">Latest Issue</Link>
                            <Link to="/issues/" className="text-link beneath">Archive</Link>
                        </div>
                        <div id="w-node-5bb374513170-74513152" className="footer-column">
                            <h4 className="footer-h4">Information</h4>
                            <Link to="/about/" className="text-link">About</Link>
                            <Link to="/staff/" className="text-link beneath">Staff</Link>
                            <Link to="/blog/" className="text-link beneath">Blog</Link>
                        </div>
                        <div id="w-node-5bb374513179-74513152" className="footer-column">
                            <h4 className="footer-h4">Contact</h4>
                            <Link to="/submissions/" className="text-link">Submit Work</Link>
                            <Link to="/help-out/" className="text-link beneath">Support <em>The Pub</em></Link>
                            <a href="https://forms.gle/etRdnyWMcE3nbYAt9" target="_blank" rel="noopener noreferrer" className="text-link beneath">Staff Applications</a>
                            <a href="https://www.instagram.com/wheatonpub/" target="_blank" rel="noopener noreferrer" className="text-link beneath">Instagram</a>
                            <a href="mailto:wheatonpub@my.wheaton.edu" target="_blank" rel="noopener noreferrer" className="text-link beneath">Email</a>
                        </div>
                        <div id="w-node-5bb374513186-74513152" className="footer-final-things">
                            <div>Copyright © 2024 <em>The Pub</em><br /></div>
                            <div>Website by <a href="https://www.efonov.com/" target="_blank" rel="noopener noreferrer" >@efonov</a></div>
                        </div>
                    </div>
                    <motion.div className="footer-background" style={{ x: useSpring(useTransform(scrollYProgress, [0.5, 1], [0, -(isBrowser ? window.innerWidth / 3 : 300)]), { damping: { damping } }, { stiffness: { stiffness } }) }} data-w-id="171a3cb8-d852-d3fc-e501-5bb37451318d" > <img src={footerLogo} alt="The Pub Footer Logo" className="footer-logo" /></motion.div>
                </div>
            </motion.div>
        )
    return rawHTML;
}
